<!-- =========================================================================================
    File Name: Invoice.vue
    Description: Invoice Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="invoice-page">
  
        <div class="flex flex-wrap items-center justify-between">
        <div class="flex items-center">
            <!--<vs-button class="mb-base mr-3" type="border" icon-pack="feather" icon="icon icon-download">Download</vs-button>-->
            <vs-button :to="{ name: 'sickLeaves'}" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">Retour</vs-button>
          </div>
          <div class="flex items-center">
            <!--<vs-button class="mb-base mr-3" type="border" icon-pack="feather" icon="icon icon-download">Download</vs-button>-->
            <vs-button color="success" type="filled" class="mb-base m-3" icon-pack="feather" icon="icon icon-file" @click="printInvoice">Imprimer</vs-button>
          </div>
        </div>
  
        <vx-card id="invoice-container">
            <!-- INVOICE METADATA -->
            <div class="vx-row leading-loose p-base">
                <div class="vx-col w-1/2 mt-base">
                  <div class="vx-col w-1/2 invoice__recipient-info">
                    <h8>POLYCLINIQUE</h8>
                    </div>
                    <img src="@/assets/images/logo/logoprescription.png" alt="vuexy-logo">
                </div>
                <div class="vx-col w-1/2 text-right">
                    <h5>CERTIFICAT MEDICAL DE REPOS</h5>
                    <div class="invoice__invoice-detail mt-2">
                        <h6>N° FICHE</h6>
                        <p>085AZ04</p>
                        <h6>DATE</h6>
                        <p>{{ invoice_data.created_at | date_time }}</p>
                    </div>
                </div>
            </div>
  
            <!-- INVOICE CONTENT -->
            <div class="p-base">
              <div Align="Center"><h1 class="font-semibold"><U>CERTIFICAT MEDICAL DE REPOS</U></h1></div>
              <p class="pt-12">Je soussigné Dr. <b>{{invoice_data.doctor.last_name}}{{'  '}}{{invoice_data.doctor.first_name}},</b></p>
              <p>
                    <span class="mr-1">atteste que Mme/M. <span class="font-semibold">{{invoice_data.patient.last_name}}{{'  '}}{{invoice_data.patient.first_name}} </span> a éte vu dans notre institution sanitaire le </span>
                    <span class="mr-2"><span class="font-semibold">{{invoice_data.startDate|moment}}</span> son état de santé nécessite un repos de </span>
                    <span class="mr-2"><span class="font-semibold">02 jour(s)</span> allant du</span>
                    <span><span class="font-semibold">{{invoice_data.startDate|moment}}</span> au </span>
                    <span><span class="font-semibold">{{invoice_data.endDate|moment}}</span> inclus pour continuer le traitement.</span>
              </p>
              <p class="pt-8">
                <span>En foi de quoi le présent certificat lui est délivré pour servir et valoir ce que de droit.</span>
              </p>
              <div class="pt-12" Align="RIGHT"><p><span>Lomé, le <span class="font-semibold">{{ invoice_data.created_at | date_time }}</span></span></p></div>
            </div>
  
            <!-- INVOICE FOOTER 
            <div class="invoice__footer text-right p-base">
                <p >POLYCLINIQUE INTERNATIONALE SAINT JOSEPH DE LOME</p>
                <p>
                    <span >LOCALISATION: <span class="font-semibold">Lomé-Togo Bd. du Haho - Quartier Tokoin Hédzanawoé</span></span>
                    <span > BP : <span class="font-semibold"> 10039 Lomé </span></span>
                </p>
                <p >
                  <span>REF : <span class="font-semibold"> ETG3638</span></span>
                  <span> TEL : <span class="font-semibold"> +228 22 26 72 32</span></span>
                  <span> SITE WEB: <span class="font-semibold">  http://www.stjoclinic-togo.com</span></span>

                </p>

            </div>-->
        </vx-card>
  
        <div class="flex flex-wrap items-center justify-between">
        <div class="flex items-center">
           
          </div>
          <div class="flex items-center">
            <!--<vs-button class="mb-base mr-3" type="border" icon-pack="feather" icon="icon icon-download">Download</vs-button>-->
            <vs-button color="success" type="filled" class="mb-base mt-6" icon-pack="feather" icon="icon icon-file" @click="printInvoice">Imprimer</vs-button>
          </div>
        </div>
  
    </div>
  </template>
  
<script>
import router from '@/router'
export default{
  data () {
    return {
      exist_prescription: '',
      invoice_data:[],
      roleObject: {
        admin: 'Administrateur',
        dentist: 'Dentiste',
        generalist:'Généraliste',
        secretary: 'Sécrétaire',
        accountant: 'Comptable'
      },
      genderObject: {
        male: 'Masculin',
        female: 'Féminin'
          
      }
    }
  },
  computed: {
  },
  methods: {
    printInvoice () {
      document.title = 'CERTIFICAT MEDICAL'
      window.print()
    }
  },
  components: {},
  
  mounted () {
    if (this.exist_prescription !== '') {
      this.$emit('setAppClasses', 'invoice-page')
      this.invoice_data = this.$store.state.prescription.sickleaveinvoice
    } else {
      router.push(router.currentRoute.query.to || 'sickLeaves')
  
    }
     
  },
  
  created () {
    this.exist_prescription = this.$store.state.prescription.sickleaveinvoice   
  }
  
}
</script>
  
  <style lang="scss">
  @media print {
  .invoice-page {
    * {
      visibility: hidden;
    }
  
    #content-area {
      margin: 0 !important;
    }
  
    .vs-con-table {
      .vs-con-tbody {
        overflow: hidden !important;
      }
    }
  
    #invoice-container,
    #invoice-container * {
      visibility: visible;
    }
    #invoice-container {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
  }
  }
  
  @page {
  size: auto;
  }
  @media print {
  
  .invoice__footer {
    display: block;
    position: fixed;
    text-align: end;
    bottom: 0;
    height: 35mm;
  }

 
}
  </style>
  